(function( $, window, document, undefined ) {

	$.smoothScroll = function(element, options) {
		'use strict';

		var defaults = {
			threshold: 0
		};

		var plugin = this;
		plugin.settings = {};
		plugin.$element = $( element );
		plugin.element = element;

		plugin.init = function() {
			plugin.settings = $.extend({}, defaults, options);
      plugin.target = plugin.$element.attr('href');
      plugin.$element.on('click', scrollToAnchor);
		};

    var scrollToAnchor = function(event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: $(document).find(plugin.target).offset().top - plugin.settings.threshold
      });
    };

		plugin.init();
	};

	$.fn.smoothScroll = function( options ) {
		return this.each( function() {
			if ( undefined == $( this ).data( 'smoothScroll' )) {
				var plugin = new $.smoothScroll( this, options );
				$( this ).data( 'smoothScroll', plugin );
			}
		});
	};
})( jQuery, window, document );
