(function( $, window, document, undefined ) {

	$.thirdPartyEmbed = function(element, options) {
		'use strict';

		var defaults = {
			src: '',
      poster: '',
      alt: ''
		};

		var plugin = this;
		plugin.settings = {};
		plugin.$element = $( element );
		plugin.element = element;

		plugin.init = function() {
			plugin.settings = $.extend({}, defaults, options);
      plugin.settings.src = plugin.$element.data('src');
      plugin.settings.alt = plugin.$element.data('alt');
      plugin.settings.poster = plugin.$element.data('poster');
      plugin.$element.html('<img src="'+plugin.settings.poster+'" alt="'+plugin.settings.alt+'">');
      plugin.$element.on('click', embed);
		};

    var embed = function() {
      plugin.$element.html('<iframe src="'+plugin.settings.src+'" frameborder="0" allowfullscreen></iframe>');
    };

		plugin.init();
	};

	$.fn.thirdPartyEmbed = function( options ) {
		return this.each( function() {
			if ( undefined == $( this ).data( 'thirdPartyEmbed' )) {
				var plugin = new $.thirdPartyEmbed( this, options );
				$( this ).data( 'thirdPartyEmbed', plugin );
			}
		});
	};
})( jQuery, window, document );
