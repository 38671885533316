$('.smooth-scroll').smoothScroll({
  threshold: 116
});

$('.data-third-party-embed').thirdPartyEmbed();

$(document).on('scroll', function() {
  var offset = $(document).scrollTop();
  if (offset >= 50) {
    $('#logo').css({
      width: '60%'
    });
  } else {
    $('#logo').css({
      width: '100%'
    });
  }
});
